import React, { createRef, useState } from 'react';
import CrmCard from 'components/common/CrmCard';
import Grid from '@material-ui/core/Grid';
import CrmLoader from 'components/common/CrmLoader';
import CrmTable from 'components/common/CrmTable';
import ListaPreventiviDashboardTableConfig from 'tableConfig/ListaPreventiviDashboardTableConfig';
import ListaClientiDashboardTableConfig from 'tableConfig/ListaClientiDashboardTableConfig';
import ChartConstants from 'constants/ChartConstants';
import DoughnutChartDashboardWrapper from 'components/specific/DoughnutChartDashboardWrapper';
import VerticalBarReChart from 'components/charts/VerticalBarReChart';
import { useLazyQuery } from '@apollo/client';
import Client from 'client/Client';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import Capitalize from 'utils/Capitalize';
import getFilteredArray from 'utils/GetFilteredArray';
import findInMap from 'utils/findInMap';
import { Button } from '@material-ui/core';
import { useTranslation, Trans } from '@tecma/i18n';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CrmScreenshotButton from 'components/common/CrmScreenshotButton';
import { createBuildingsStructure, listaAccorpamenti, filterAccorpamenti } from 'utils/appartment';
import { getCombinations, getQuadrantsForCombinations } from 'utils/quadrant';
import { BOOKED_STATUSES, NOT_BOOKED_STATUSES } from 'constants/bookingStatuses';
import { DAS_PROMO_MULTIPLIER } from 'constants/dasConstants';
import DasEconomicDashboard from 'components/specific/DasEconomicDashboard.jsx';
import { avg, sum, sumObjArray } from 'utils/mathUtils';
import colors from 'styles/colors.module.scss';
import CrmAppBar from 'components/common/CrmAppBar';
import RegisteredUsersView from 'components/charts/RegisteredUsersView';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ClickAwayListener } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CrmCalendarRange from 'components/common/CrmCalendarRange';
import moment from 'utils/MyMoment';
import TotalAppointmentsView from 'components/charts/TotalAppointmentsView';
import NoDataChartDashboard from 'components/specific/NoDataChartDashboard';
import ListaMixTipologicoTableConfig from 'tableConfig/ListaMixTipologicoTableConfig';
import { filterAptStatusFlatList } from 'utils/CalculateAptStatus';
/**
 * Home page
 * Contiene le tabelle clienti e appartamenti, oltre ai grafici
 * @component
 * @see CrmTable
 * @see ListaClienti
 * @see ListaAppartamenti
 * @see DoughnutChartDashboardWrapper
 * @see HorizontalBarReChart
 * @see VerticalBarReChart
 * @see FatturatoWrapper
 */
const Home = observer(() => {
  const { t } = useTranslation();

  const screenshotRef = createRef(null);
  const store = useStore();
  const lean = true;
  const [appartmentsCall, appartments] = useLazyQuery(Client.GET_ALL_APPARTMENTS, Client.PROJECTID_GENERIC_OPTION_NOCACHE(store.projectId, undefined, lean));
  const [floorsCall, floors] = useLazyQuery(Client.GET_ALL_FLOORS, Client.PROJECTID_GENERIC_OPTION_NOCACHE(store.projectId, undefined, lean));
  const [clientsCall, clients] = useLazyQuery(Client.GET_ALL_CLIENTS, Client.PROJECTID_GENERIC_OPTION_NOCACHE(store.projectId));
  const [typologiesCall, typologies] = useLazyQuery(Client.GET_TYPOLOGIES, Client.PROJECTID_GENERIC_OPTION_NOCACHE(store.projectId));
  const [projectInfoCall, projectInfo] = useLazyQuery(
    Client.GET_PROJECT_INFORMATIONS_APPOINTMENT,
    Client.PROJECTID_GENERIC_OPTION_NOCACHE(
      store.projectId,
      store.retrieveClientsListDashboard.map((client) => client.id),
    ),
  );
  const [featuresCall, features] = useLazyQuery(Client.GET_PROJECT_FEATURES, Client.PROJECTID_GENERIC_OPTION_NOCACHE(store.projectId, undefined, lean));
  const [promoPriceCall, promoPrice] = useLazyQuery(Client.GET_APARTMENTS_PROMO_PRICE, Client.PROJECTID_GENERIC_OPTION_NOCACHE(store.projectId));
  const [dasProjectStats, setDasProjectStats] = React.useState(undefined);
  const [aptBookingStats, setAptBookingStats] = React.useState(undefined);
  const [featureBookingStats, setFeatureBookingStats] = React.useState(undefined);
  const [bookedNotBookedAptFts, setBookedNotBookedAptFts] = React.useState([]);
  const [doughnutAptFts, setDoughnutAptFts] = React.useState([]);
  const [bookedNotBookedApt, setBookedNotBookedApt] = React.useState([]);
  const [doughnutApt, setDoughnutApt] = React.useState([]);
  const [bookedNotBookedFts, setBookedNotBookedFts] = React.useState([]);
  const [doughnutFts, setDoughnutFts] = React.useState([]);
  const [promosArray, setPromosArray] = React.useState([]);
  const [hasPromosArray, setHasPromosArray] = React.useState(false);
  const [verticalDataBars, setVerticalDataBars] = React.useState([]);
  const [minListDimension, setMinListDimension] = React.useState([]);
  const [maxListDimension, setMaxListDimension] = React.useState([]);
  const [doughnutApartmentOnFocus, setDoughnutApartmentOnFocus] = React.useState();
  const [showDoughnutTable, setShowDoughnutTable] = React.useState(false);
  const [minListinoApartments, setMinListinoApartments] = React.useState([]);
  const [maxListinoApartments, setMaxListinoApartments] = React.useState([]);
  const [tabMixTypologicalData, setTabMixTypologicalData] = React.useState([]);

  if (store.projectId && !appartments.called && !clients.called && !typologies.called && !projectInfo.called && !features.called) {
    appartmentsCall();
    floorsCall();
    typologiesCall();
    clientsCall();
    projectInfoCall();
    featuresCall();
  }

  React.useEffect(() => {
    if (appartments.error && !appartments.error.message.includes('Observable cancelled prematurely')) {
      store.setSnackbarError(true, t('snackbar.errorUploadApartment'));
    }
    // eslint-disable-next-line
  }, [appartments.error]);

  React.useEffect(() => {
    if (clients.error && !clients.error.message.includes('Observable cancelled prematurely')) {
      store.setSnackbarError(true, t('snackbar.errorUploadClient_'));
    }
    // eslint-disable-next-line
  }, [clients.error]);

  React.useEffect(() => {
    if (typologies.error && !typologies.error.message.includes('Observable cancelled prematurely')) {
      store.setSnackbarError(true, t('snackbar.errorUploadTypologyApart'));
    }
    // eslint-disable-next-line
  }, [typologies.error]);

  React.useEffect(() => {
    if (typologies.data) {
      store.setAppartmentTypologies(
        typologies.data.getAllTypologies.slice(0).sort((a, b) => {
          if (a.rooms > b.rooms) {
            return 1;
          }
          if (a.rooms < b.rooms) {
            return -1;
          }
          return 0;
        }),
      );
    }
    // eslint-disable-next-line
  }, [typologies.data]);

  let apartmentsData = appartments.data ? appartments.data.getAllAppartments : [];
  const featuresData = features.data ? features.data.getFeatures : [];
  const hasDAS = !!apartmentsData.length && ChartConstants.isDASProject(apartmentsData);
  const availableStatuses = filterAptStatusFlatList(ChartConstants.availableStatus, store.enabledStatus);
  const availableStatusesDAS = hasDAS
    ? filterAptStatusFlatList(
        ChartConstants.availableStatus.filter((status) => status !== 'libero'),
        store.enabledStatus,
      )
    : [];
  const availableColors = ChartConstants.defaultColors;
  const availableColorsDAS = ChartConstants.defaultColors.filter((color) => color !== colors.graph_first_color);
  const finalAvailableStats = hasDAS ? availableStatusesDAS : availableStatuses;
  const finalAvailableColors = hasDAS ? availableColorsDAS : availableColors;
  const tabList = [
    {
      name: 'Dashboard Appartamenti & Pertinenze',
      id: 'Dashboard Appartamenti & Pertinenze',
    },
    {
      name: 'Dashboard Client',
      id: 'Dashboard Client',
    },
  ];
  if (hasDAS) {
    tabList.push({
      name: 'Dashboard DAS',
      id: 'Dashboard DAS',
    });
  }
  const arrayClients = store.retrieveClientsListDashboard;

  let totalClients = arrayClients.length;

  let appuntamentiFissati = 0;
  let appuntamentiGestitiTotali = 0;
  let primiAppuntamentiGestiti = 0;
  let secondiAppuntamentiGestiti = 0;
  let terziAppuntamentiGestiti = 0;
  let altriAppuntamentiGestiti = 0;
  let appuntamentiCancellati = 0;

  if (projectInfo.data && projectInfo.data.getProjectInformationsAppointment) {
    appuntamentiFissati = projectInfo.data.getProjectInformationsAppointment.find((info) => info.name === 'event').numero;
    appuntamentiGestitiTotali = projectInfo.data.getProjectInformationsAppointment.find((info) => info.tipologia === 'Appuntamenti Gestiti Totali').numero;
    primiAppuntamentiGestiti = projectInfo.data.getProjectInformationsAppointment.find((info) => info.tipologia === 'Primi Appuntamenti Gestiti').numero;
    secondiAppuntamentiGestiti = projectInfo.data.getProjectInformationsAppointment.find((info) => info.tipologia === 'Secondi Appuntamenti Gestiti').numero;
    terziAppuntamentiGestiti = projectInfo.data.getProjectInformationsAppointment.find((info) => info.tipologia === 'Terzi Appuntamenti Gestiti').numero;
    altriAppuntamentiGestiti = projectInfo.data.getProjectInformationsAppointment.find((info) => info.tipologia === 'Altri Appuntamenti Gestiti').numero;
    appuntamentiCancellati = projectInfo.data.getProjectInformationsAppointment.find((info) => info.name === 'deletedEvent').numero;
  }

  const [openInfoTooltip, setOpenInfoTooltip] = React.useState(false);

  const handleTooltipButton = () => {
    setOpenInfoTooltip(!openInfoTooltip);
  };

  const handleTooltip = () => {
    setOpenInfoTooltip(false);
  };

  const InfoTooltip = withStyles({
    tooltip: {
      backgroundColor: 'rgba(30, 32, 31, 0.95)',
      minWidth: 400,
      padding: 15,
      position: 'absolute',
      right: 10,
      top: -10,
      zIndex: 1,
      overflow: 'visible',
      '@media (max-width: 960px)': {
        minWidth: 280,
        height: 370,
      },
      '@media (max-width: 600px)': {
        minWidth: 200,
        height: 'fit-content',
      },
    },
  })(Tooltip);

  const verticalGraphsData = (arrData) => {
    return arrData
      ? store.typologies.map((size) => {
          const res = {
            label: Capitalize(findInMap(ChartConstants.shortNames, size.name)),
          };
          const appartmentsBySize = arrData.filter((app) => app.plan.typology.name === size.name);
          finalAvailableStats.map((stat) => (res[stat] = getFilteredArray(appartmentsBySize, 'status', stat).length));
          return res;
        })
      : [];
  };

  const calcBookingStats = (elements) => {
    if (!elements || !Array.isArray(elements) | (!elements.length === 0)) {
      return [];
    }
    const booked_statuses = BOOKED_STATUSES.map(({ status }) => status);
    const bookingStats = elements.reduce(
      (acc, element) => {
        if (element.status) {
          if (booked_statuses.includes(element.status)) {
            acc.booked.quantity += 1;
            acc.booked.value += element.price;
            acc.booked[element.status].quantity += 1;
            acc.booked[element.status].value += element.price;
          } else {
            acc.notBooked.quantity += 1;
            acc.notBooked.value += element.price;
            if (acc.notBooked[element.status]) {
              acc.notBooked[element.status].quantity += 1;
              acc.notBooked[element.status].value += element.price;
            } else {
              // status not in NOT_BOOKED_STATUSES
              acc.notBooked[element.status] = { quantity: 1, value: element.price };
            }
          }
        }
        return acc;
      },
      {
        booked: {
          quantity: 0,
          value: 0,
          ...BOOKED_STATUSES.reduce((acc, { status }) => {
            acc[status] = { quantity: 0, value: 0 };
            return acc;
          }, {}),
        },
        notBooked: {
          quantity: 0,
          value: 0,
          ...NOT_BOOKED_STATUSES.reduce((acc, { status }) => {
            acc[status] = { quantity: 0, value: 0 };
            return acc;
          }, {}),
        },
      },
    );
    bookingStats.totalQuantity = bookingStats.booked.quantity + bookingStats.notBooked.quantity;
    bookingStats.totalValue = bookingStats.booked.value + bookingStats.notBooked.value;
    return bookingStats;
  };

  React.useEffect(() => {
    if (apartmentsData.length > 0) {
      apartmentsData = apartmentsData.map((apt) => {
        return { ...apt, available: true, enabled_web: true, enabled_desk: true, oldAvailable: apt.available };
      });
      if (hasDAS && floors.data) {
        const auxAccorpamenti = listaAccorpamenti(apartmentsData);
        const buildingsStructure = createBuildingsStructure(apartmentsData);
        const buildingStats = Object.entries(buildingsStructure).map(([buildingId, building]) => {
          // mi prendo tutti i piani
          const complexQuadrants = floors.data.getAllFloors
            // filtro per quelli che hanno il buildingId che mi interessa
            .filter((f) => f.building.id == buildingId)
            .map((floor) => {
              return floor.quadrantsObjects.map((quad) => {
                return { ...quad, floor: floor.floor, available: 1 };
              });
            })
            .flat()
            .map((floor) => {
              return { ...floor };
            });
          // complexQuadrants è un array con oggetti con chiave cod il quadrante, il piano in cui si trova e available a 1
          const accorpamentiValutati = [];
          // per ogni floor dentro l'oggetto di buildingStructures
          const floorStats = Object.values(building.floors).map((floor) => {
            // prendo dalla lista degli accorpamenti quelli che sono nel piano di interesse
            let filteredAccorpamenti = auxAccorpamenti[buildingId]?.floors[floor.appartamenti[0].floor].accorpamenti || [];
            let maxPrice = 0,
              minPrice = 0;
            let myMaxApt = [],
              myMinApt = [];
            // mi interesso solo degli accorpamenti che non ho valutato
            filteredAccorpamenti = filteredAccorpamenti.filter((f) => !accorpamentiValutati.includes(f.code));
            while (filteredAccorpamenti.length > 0) {
              const cluster = [];
              filterAccorpamenti([...filteredAccorpamenti], filteredAccorpamenti[0], floor.appartamenti[0].floor, cluster);

              filteredAccorpamenti = filteredAccorpamenti.filter((acc) => {
                return (
                  cluster.filter((cl) => {
                    return cl.code === acc.code;
                  }).length == 0
                );
              });
              // per ogni accorpamento dentro il cluster lo aggiungo in accorpamenti valutati
              cluster.forEach((cl) => {
                accorpamentiValutati.push(cl.code);
              });

              const quadrantsForCombinations = getQuadrantsForCombinations(cluster, complexQuadrants, floor.appartamenti[0].floor);

              const combinazioniAccorpamenti = getCombinations(cluster, quadrantsForCombinations, floor.appartamenti[0].floor, {}, buildingId);

              // calcolo un array con la somma dei prezzi per combinazione
              const combPrice =
                combinazioniAccorpamenti.length > 0
                  ? combinazioniAccorpamenti.map((acc) => {
                      return acc.reduce(
                        (acc, curr) => {
                          acc.sum = acc.sum + curr.price;
                          acc.apt.push(curr.aptOfInterest);
                          return acc;
                        },
                        { sum: 0, apt: [] },
                      );
                    })
                  : [];
              if (combPrice.length > 0) {
                const prices = combPrice.map((el) => el.sum);
                const maxFoor = Math.max(...prices);
                const minFloor = Math.min(...prices);
                const minApt = combPrice.filter((el) => el.sum === minFloor)[0].apt;
                const maxApt = combPrice.filter((el) => el.sum === maxFoor)[0].apt;
                myMinApt = myMinApt.concat(minApt);
                myMaxApt = myMaxApt.concat(maxApt);
                maxPrice += maxFoor;
                minPrice += minFloor;
              }
            }
            // minApt e maxApt sono array con tutti gli appartamenti che hanno aiutato a calcolare min e max
            return { maxFloorPrice: maxPrice, minFloorPrice: minPrice, maxApt: myMaxApt, minApt: myMinApt };
          });
          return floorStats.reduce((acc, curr) => {
            acc.maxBuildingPrice = acc.maxBuildingPrice ? acc.maxBuildingPrice + curr.maxFloorPrice : curr.maxFloorPrice;
            acc.minBuildingPrice = acc.minBuildingPrice ? acc.minBuildingPrice + curr.minFloorPrice : curr.minFloorPrice;
            acc.minBuildingApt = acc.minBuildingApt ? acc.minBuildingApt.concat(curr.minApt) : curr.minApt;
            acc.maxBuildingApt = acc.maxBuildingApt ? acc.maxBuildingApt.concat(curr.maxApt) : curr.maxApt;
            return acc;
          }, {});
        });
        const projectStats = buildingStats.reduce((acc, curr) => {
          acc.maxProjectPrice = acc.maxProjectPrice ? acc.maxProjectPrice + curr.maxBuildingPrice : curr.maxBuildingPrice;
          acc.minProjectPrice = acc.minProjectPrice ? acc.minProjectPrice + curr.minBuildingPrice : curr.minBuildingPrice;
          return acc;
        }, {});

        let myMaxBuildingApt = [];
        buildingStats.map((el) => {
          myMaxBuildingApt = myMaxBuildingApt.concat(el.maxBuildingApt);
        }, []);
        let myMinBuildingApt = [];
        buildingStats.map((el) => {
          myMinBuildingApt = myMinBuildingApt.concat(el.minBuildingApt);
        }, []);
        setMinListinoApartments(myMinBuildingApt);
        setMaxListinoApartments(myMaxBuildingApt);
        setMinListDimension(ChartConstants.transformToDimensionDoughnutChart(myMinBuildingApt, '#2c73d2'));
        setMaxListDimension(ChartConstants.transformToDimensionDoughnutChart(myMaxBuildingApt, '#29bb88'));
        setDasProjectStats(projectStats);
      }
    }
  }, [apartmentsData, floors.data]);

  const deleteEmptyVertical = (data) => {
    const toReturnArray = [];
    for (let i = 0; i < data.length; i++) {
      const myElement = data[i];
      let toDelete = true;
      for (const property in myElement) {
        if (myElement[property] > 0) {
          toDelete = false;
          break;
        }
      }
      if (!toDelete) {
        toReturnArray.push(myElement);
      }
    }
    return toReturnArray;
  };

  const saveSearchClienti = (value) => {
    store.setSearchClientiHome(value);
  };
  const saveSearchAppartamenti = (value) => {
    store.setSearchAppartamentiHome(value);
  };
  const filterAptsWithoutPromoPrice = (apt) => ['libero', 'interesse'].includes(apt.status);

  let barSize = 140;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const matchesLg = useMediaQuery('(max-width:1279px)', { noSsr: true });

  if (matches) {
    barSize = 60;
  }

  React.useEffect(() => {
    if (apartmentsData && apartmentsData.length > 0) {
      const filteredApartments = apartmentsData
        .filter((apt) => {
          return apt.status !== 'libero' && apt.status !== 'interesse';
        })
        .map((apt) => ({ id: apt.id, status: apt.status }));
      if (filteredApartments) {
        promoPriceCall(Client.GET_APARTMENTS_PROMO_PRICE_DEFAULT_OPTIONS(store.projectId, filteredApartments));
      }
    }
  }, [apartmentsData]);

  React.useEffect(() => {
    if (promoPrice.data && promoPrice.data.getApartmentsPromoPrice && promosArray.length === 0 && !hasPromosArray) {
      setHasPromosArray(true);
      setPromosArray(
        promoPrice.data.getApartmentsPromoPrice.map((promo) => {
          if (promo.promoPrice <= 0) {
            const promoToPush = { ...promo };
            const aptOfPromo = apartmentsData.find((apt) => {
              return apt.id === promo.apartmentId;
            });

            promoToPush.promoPrice = aptOfPromo.price;
            return promoToPush;
          } else {
            return promo;
          }
        }),
      );
    }
  }, [promoPrice.data, featuresData]);

  React.useEffect(() => {
    return () => {
      setDasProjectStats([]);
      setAptBookingStats([]);
      setFeatureBookingStats([]);
      setDoughnutAptFts([]);
      setBookedNotBookedAptFts([]);
      setDoughnutApt([]);
      setBookedNotBookedApt([]);
      setDoughnutFts([]);
      setBookedNotBookedFts([]);
      setVerticalDataBars([]);
      setPromosArray([]);

      // DAS LOADING
      setAptBookingStats([]);
      setFeatureBookingStats([]);
    };
  }, []);

  React.useEffect(() => {
    if (!appartments.loading && appartments.called && !features.loading && features.called && (apartmentsData.length === 0 || (!promoPrice.loading && promoPrice.called))) {
      setDoughnutAptFts(
        ChartConstants.transformToDoughnutChart(finalAvailableStats, apartmentsData, promosArray, featuresData).map((val) => {
          val.total = undefined;
          return val;
        }),
      );
      setBookedNotBookedAptFts(
        ChartConstants.transformBookedNotBookedChart(apartmentsData.concat(featuresData)).map((val) => {
          val.total = undefined;
          return val;
        }),
      );
      setBookedNotBookedApt(ChartConstants.transformBookedNotBookedChart(apartmentsData));
      setDoughnutApt(ChartConstants.transformToDoughnutChart(finalAvailableStats, apartmentsData, promosArray));
      setDoughnutFts(ChartConstants.transformToDoughnutChart(availableStatuses, featuresData));
      setBookedNotBookedFts(ChartConstants.transformBookedNotBookedChart(featuresData));
      setVerticalDataBars(verticalGraphsData(apartmentsData));

      // DAS LOADING
      setAptBookingStats(calcBookingStats(apartmentsData));
      setFeatureBookingStats(calcBookingStats(featuresData));
    }
  }, [apartmentsData, featuresData, promosArray]);
  const [tabDashboard, setTabDashboard] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabDashboard(newValue);
    // reset "Dashboard DAS" drilldown on tab change
    setDoughnutApartmentOnFocus('');
    setShowDoughnutTable(false);
  };

  function calcolaPerc(tot, num) {
    return ((num / tot) * 100).toFixed(1);
  }

  const registerdUsers = () => {
    let utentiLead = 0;
    let utentiProspect = 0;
    let utentiClient = 0;
    let utentiDaRichiamare = 0;

    if (totalClients > 0) {
      arrayClients.forEach((element) => {
        switch (element.status) {
          case 'lead':
            utentiLead++;
            break;
          case 'prospect':
            utentiProspect++;
            break;
          default:
            utentiClient++;
        }
        if (element.additionalInfo && element.additionalInfo.appuntamenti_fissati === 'da ricontattare') utentiDaRichiamare++;
      });
    }
    return [
      {
        name: <Trans i18nKey={'dashboard.utentiLead'} />,
        number: utentiLead,
        differenceTotal: totalClients - utentiLead,
        percentuale: '(' + calcolaPerc(totalClients, utentiLead) + '%)',
      },
      {
        name: <Trans i18nKey={'dashboard.utentiProspect'} />,
        number: utentiProspect,
        differenceTotal: totalClients - utentiProspect,
        percentuale: '(' + calcolaPerc(totalClients, utentiProspect) + '%)',
      },
      {
        name: <Trans i18nKey={'dashboard.utentiClient'} />,
        number: utentiClient,
        differenceTotal: totalClients - utentiClient,
        percentuale: '(' + calcolaPerc(totalClients, utentiClient) + '%)',
      },
      {
        name: <Trans i18nKey={'dashboard.utentiRecall'} />,
        number: utentiDaRichiamare,
        differenceTotal: totalClients - utentiDaRichiamare,
        percentuale: '(' + calcolaPerc(totalClients, utentiDaRichiamare) + '%)',
      },
    ];
  };

  const totalAppointments = [
    {
      name: <Trans i18nKey={'dashboard.appuntamentiGestitiTotali'} />,
      number: appuntamentiGestitiTotali,
      differenceTotal: 0,
      total: appuntamentiGestitiTotali,
    },
    {
      name: <Trans i18nKey={'dashboard.primiAppuntamentiGestiti'} />,
      number: primiAppuntamentiGestiti,
      differenceTotal: appuntamentiGestitiTotali - primiAppuntamentiGestiti,
      percentuale: '(' + calcolaPerc(appuntamentiGestitiTotali, primiAppuntamentiGestiti) + '%)',
      total: appuntamentiGestitiTotali,
    },
    {
      name: <Trans i18nKey={'dashboard.secondiAppuntamentiGestiti'} />,
      number: secondiAppuntamentiGestiti,
      differenceTotal: appuntamentiGestitiTotali - secondiAppuntamentiGestiti,
      percentuale: '(' + calcolaPerc(appuntamentiGestitiTotali, secondiAppuntamentiGestiti) + '%)',
      total: appuntamentiGestitiTotali,
    },
    {
      name: <Trans i18nKey={'dashboard.terziAppuntamentiGestiti'} />,
      number: terziAppuntamentiGestiti,
      differenceTotal: appuntamentiGestitiTotali - terziAppuntamentiGestiti,
      percentuale: '(' + calcolaPerc(appuntamentiGestitiTotali, terziAppuntamentiGestiti) + '%)',
      total: appuntamentiGestitiTotali,
    },
    {
      name: <Trans i18nKey={'dashboard.altriAppuntamentiGestiti'} />,
      number: altriAppuntamentiGestiti,
      differenceTotal: appuntamentiGestitiTotali - altriAppuntamentiGestiti,
      percentuale: '(' + calcolaPerc(appuntamentiGestitiTotali, altriAppuntamentiGestiti) + '%)',
      total: appuntamentiGestitiTotali,
    },
  ];

  React.useEffect(() => {
    if (clients && !clients.loading && clients.called) {
      store.setClients(clients.data ? clients.data.getAllClientsFollowUp : []);
    }
  }, [clients]);

  const updateEndDate = (newDate) => {
    store.setClientsToFilterDashboard(newDate);
  };
  const updateStartDate = (newDate) => {
    store.setClientsFromFilterDashboard(newDate);
  };

  const [defaultStartDate, setDefaultStartDate] = React.useState(new Date(2021, 0, 1));
  const [minDate, setMinDate] = React.useState(undefined);

  React.useEffect(() => {
    if (store.clients) {
      store.clients.forEach((data) => {
        if (minDate) {
          const createdOn = new Date(data.createdOn);
          if (moment(createdOn).diff(moment(minDate)) < 0) {
            setMinDate(createdOn);
            setDefaultStartDate(createdOn);
          }
        } else {
          const firstCreatedOn = new Date(data.createdOn);
          setMinDate(firstCreatedOn);
          setDefaultStartDate(firstCreatedOn);
        }
      });
    }
  }, [store.clients]);

  let projectInfoLoading = !projectInfo.called || projectInfo.loading;
  let appartmentsLoading = !appartments.called || appartments.loading;
  let clientsLoading = !clients.called || clients.loading;
  let featuresLoading = features.loading || !features.called;
  let promoPriceLoading = promoPrice.loading || (!promoPrice.called && apartmentsData && apartmentsData.length > 0);

  const clickOnMaxDoughnutChart = (data) => {
    const type = data.split('_')[1];
    const myData = data.split('_')[0];
    if (type === 'max' && data === doughnutApartmentOnFocus) {
      setDoughnutApartmentOnFocus('');
      setShowDoughnutTable(!showDoughnutTable);
    } else {
      setShowDoughnutTable(true);
      setDoughnutApartmentOnFocus(data);
    }
    const dataOfInterest = [];
    maxListinoApartments.forEach((el) => {
      if (el.plan && el.plan.typology && el.plan.typology.name === myData) {
        dataOfInterest.push(el);
      }
    });
    setTabMixTypologicalData(dataOfInterest);
  };

  const clickOnMinDoughnutChart = (data) => {
    const myData = data.split('_')[0];
    const type = data.split('_')[1];
    if (type === 'min' && data === doughnutApartmentOnFocus) {
      setDoughnutApartmentOnFocus('');
      setShowDoughnutTable(!showDoughnutTable);
    } else {
      setShowDoughnutTable(true);
      setDoughnutApartmentOnFocus(data);
    }
    const dataOfInterest = [];
    minListinoApartments.forEach((el) => {
      if (el.plan && el.plan.typology && el.plan.typology.name === myData) {
        dataOfInterest.push(el);
      }
    });
    setTabMixTypologicalData(dataOfInterest);
  };

  return (
    <div className='main-container' ref={screenshotRef}>
      <div className='page-header-actions'>
        <CrmScreenshotButton screenshotClassName='main-container' screenshotRef={screenshotRef} />
        {tabDashboard === 1 && (
          <CrmCalendarRange
            start={store.clientsFromFilterDashboard ? store.clientsFromFilterDashboard : moment(defaultStartDate)}
            end={store.clientsToFilterDashboard ? store.clientsToFilterDashboard : moment(new Date())}
            max={new Date()}
            min={minDate}
            updateStart={updateStartDate}
            updateEnd={updateEndDate}
            extraClasses='lista-clienti-date-range'
          />
        )}
      </div>
      {(!store.projectId || appartmentsLoading || clientsLoading || featuresLoading || promoPriceLoading || projectInfoLoading) && <CrmLoader loading hasBackdrop={false} />}
      <Grid container>
        <div className='dashboard-info'>
          <div className='flex-column' style={{ lineHeight: '25px' }}>
            <div className='dashboard-info-title'>{t('dashboard.goodMorning', { user: store.loggedUser.firstName.toLowerCase() })}</div>
            <div className='small-font-14'>
              <Trans i18nKey={'dashboard.salesTrend'} />
            </div>
          </div>
        </div>
        <Grid container justifyContent='center' className='dashboard-container'>
          <Grid item xs={12} className='tab'>
            <CrmAppBar handleChange={handleChangeTab} value={tabDashboard} tabList={tabList} extraClasses='white' section='dashboard' />
          </Grid>
          <Grid item xs={12} className='item'>
            {tabDashboard === 0 && (
              <>
                {featuresData.length > 0 && (
                  <Grid item xs={10} sm={10} md={12} lg={12} data-testid='cumulative-chart' className='bottom-card main-container'>
                    <CrmCard style={{ padding: '0px' }}>
                      <div className='elements-spacing'>
                        <div className={!matches ? 'flex font-16' : 'font-16'}>
                          <div className='font-bold' style={!matches ? {} : { marginBottom: '16px' }}>
                            {t('dashboard.appartamenti&pertinenze')}
                          </div>
                          <div className={!matches ? 'flex-container-end' : ''} style={{ flex: '2' }}>
                            <div>
                              <div className={!matches ? 'flex-container-end' : 'small-font-14 flex-centered-between'}>
                                <span className='black' style={{ marginRight: '6px' }}>
                                  {hasDAS ? <Trans i18nKey={'dashboard.averageTransactionValue'} /> : <Trans i18nKey={'dashboard.transactionValue'} />}
                                </span>
                                <span className='font-bold'>
                                  {CurrencyFormatter(
                                    store.currency,
                                    hasDAS
                                      ? isNaN(avg(dasProjectStats?.maxProjectPrice + featureBookingStats?.totalValue, dasProjectStats?.minProjectPrice + featureBookingStats?.totalValue))
                                        ? 0
                                        : avg(dasProjectStats?.maxProjectPrice + featureBookingStats?.totalValue, dasProjectStats?.minProjectPrice + featureBookingStats?.totalValue)
                                      : sum(sumObjArray(apartmentsData, 'price'), sumObjArray(featuresData, 'price')),
                                  )}
                                </span>
                              </div>
                              <div style={{ color: colors.accent }} className={!matches ? 'italic flex-container-end' : 'italic small-font-14 flex-centered-between'}>
                                <span style={{ marginRight: '6px' }}>
                                  {hasDAS ? <Trans i18nKey={'dashboard.averagePromotionalTransactionValue'} /> : <Trans i18nKey={'dashboard.promotionalTransactionValue'} />}
                                </span>
                                <span className='font-bold'>
                                  {hasDAS
                                    ? CurrencyFormatter(
                                        store.currency,
                                        isNaN(
                                          avg(
                                            dasProjectStats?.maxProjectPrice * DAS_PROMO_MULTIPLIER + featureBookingStats?.totalValue,
                                            dasProjectStats?.minProjectPrice * DAS_PROMO_MULTIPLIER + featureBookingStats?.totalValue,
                                          ),
                                        )
                                          ? 0
                                          : avg(
                                              dasProjectStats?.maxProjectPrice * DAS_PROMO_MULTIPLIER + featureBookingStats?.totalValue,
                                              dasProjectStats?.minProjectPrice * DAS_PROMO_MULTIPLIER + featureBookingStats?.totalValue,
                                            ),
                                      )
                                    : CurrencyFormatter(
                                        store.currency,
                                        !promoPrice.loading &&
                                          !promoPrice.error &&
                                          sum(sumObjArray(apartmentsData?.filter(filterAptsWithoutPromoPrice), 'price'), sumObjArray(promosArray, 'promoPrice'), sumObjArray(featuresData, 'price')),
                                      )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Grid container style={{ width: 'auto' }} className='elements-spacing'>
                        <Grid item xs={12} lg={6} className='border-right-pie-chart' style={!matches ? {} : { paddingBottom: '16px' }}>
                          {hasDAS ? (
                            dasProjectStats &&
                            aptBookingStats &&
                            featureBookingStats && (
                              <DasEconomicDashboard
                                style={matches ? {} : { paddingRight: '16px' }}
                                title={t('dashboard.economicStatus')}
                                statuses={availableStatusesDAS}
                                withFeatures
                                bookedValues={{ booked: aptBookingStats.booked.value + featureBookingStats.booked.value }}
                                minListino={dasProjectStats.minProjectPrice + featureBookingStats.totalValue}
                                maxListino={dasProjectStats.maxProjectPrice + featureBookingStats.totalValue}
                                minPromo={dasProjectStats.minProjectPrice * DAS_PROMO_MULTIPLIER + featureBookingStats.totalValue}
                                maxPromo={dasProjectStats.maxProjectPrice * DAS_PROMO_MULTIPLIER + featureBookingStats.totalValue}
                              />
                            )
                          ) : (
                            <DoughnutChartDashboardWrapper title={t('dashboard.economicStatus')} data={bookedNotBookedAptFts} innerRadius={50} outerRadius={75} height={!matchesLg ? 200 : 180} />
                          )}
                        </Grid>
                        <Grid item xs={12} lg={6} style={!matchesLg ? { paddingLeft: '16px' } : { paddingTop: '16px' }}>
                          {hasDAS ? (
                            dasProjectStats &&
                            aptBookingStats &&
                            featureBookingStats && (
                              <DasEconomicDashboard
                                title={t('dashboard.economicDetail')}
                                detailed
                                statuses={availableStatusesDAS}
                                bookedValues={{
                                  proposta: aptBookingStats.booked.proposta.value + featureBookingStats.booked.proposta.value,
                                  compromesso: aptBookingStats.booked.compromesso.value + featureBookingStats.booked.compromesso.value,
                                  rogitato: aptBookingStats.booked.rogitato.value + featureBookingStats.booked.rogitato.value,
                                  booked: aptBookingStats.booked.value + featureBookingStats.booked.value,
                                }}
                                minListino={dasProjectStats.minProjectPrice + featureBookingStats.totalValue}
                                maxListino={dasProjectStats.maxProjectPrice + featureBookingStats.totalValue}
                                minPromo={dasProjectStats.minProjectPrice * DAS_PROMO_MULTIPLIER + featureBookingStats.totalValue}
                                maxPromo={dasProjectStats.maxProjectPrice * DAS_PROMO_MULTIPLIER + featureBookingStats.totalValue}
                              />
                            )
                          ) : (
                            <DoughnutChartDashboardWrapper
                              title={t('dashboard.economicDetail')}
                              data={doughnutAptFts}
                              innerRadius={50}
                              outerRadius={75}
                              height={!matchesLg ? 200 : 180}
                              showPromoLegend={!promoPrice.loading && !!promoPrice.data}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </CrmCard>
                  </Grid>
                )}
                <Grid item xs={10} sm={10} md={12} lg={12} data-testid='apt-chart' className='bottom-card main-container'>
                  <CrmCard style={{ padding: '0px' }}>
                    <div className='elements-spacing'>
                      <div className={!matches ? 'flex font-16' : 'font-16'}>
                        <div className='font-bold' style={!matches ? {} : { marginBottom: '16px' }}>
                          <Trans i18nKey={'apartments.apartments'} />
                        </div>
                        <div className={!matches ? 'flex-container-end' : ''} style={{ flex: '2' }}>
                          <div>
                            <div className={!matches ? 'flex-container-end' : 'small-font-14 flex-centered-between'}>
                              <span className='black' style={{ marginRight: '6px' }}>
                                {hasDAS ? <Trans i18nKey={'dashboard.averageTransactionValue'} /> : <Trans i18nKey={'dashboard.transactionValue'} />}
                              </span>
                              <span className='font-bold'>
                                {CurrencyFormatter(
                                  store.currency,
                                  hasDAS
                                    ? isNaN(avg(dasProjectStats?.maxProjectPrice, dasProjectStats?.minProjectPrice))
                                      ? 0
                                      : avg(dasProjectStats?.maxProjectPrice, dasProjectStats?.minProjectPrice)
                                    : sumObjArray(apartmentsData, 'price'),
                                )}
                              </span>
                            </div>
                            <div style={{ color: colors.accent }} className={!matches ? 'italic flex-container-end' : 'italic small-font-14 flex-centered-between'}>
                              <span style={{ marginRight: '6px' }}>
                                {hasDAS ? <Trans i18nKey={'dashboard.averagePromotionalTransactionValue'} /> : <Trans i18nKey={'dashboard.promotionalTransactionValue'} />}
                              </span>
                              <span className='font-bold'>
                                {hasDAS
                                  ? CurrencyFormatter(
                                      store.currency,
                                      isNaN(avg(dasProjectStats?.maxProjectPrice * DAS_PROMO_MULTIPLIER, dasProjectStats?.minProjectPrice * DAS_PROMO_MULTIPLIER))
                                        ? 0
                                        : avg(dasProjectStats?.maxProjectPrice * DAS_PROMO_MULTIPLIER, dasProjectStats?.minProjectPrice * DAS_PROMO_MULTIPLIER),
                                    )
                                  : CurrencyFormatter(
                                      store.currency,
                                      !promoPrice.loading &&
                                        !promoPrice.error &&
                                        sum(sumObjArray(apartmentsData?.filter(filterAptsWithoutPromoPrice), 'price'), sumObjArray(promosArray, 'promoPrice')),
                                    )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Grid container className='elements-spacing' style={{ width: 'auto' }}>
                      <Grid item xs={12} lg={6} className='border-right-pie-chart' style={!matches ? {} : { paddingBottom: '16px' }}>
                        {hasDAS ? (
                          dasProjectStats &&
                          aptBookingStats && (
                            <DasEconomicDashboard
                              title={t('dashboard.economicStatus')}
                              style={matches ? {} : { paddingRight: '16px' }}
                              statuses={availableStatusesDAS}
                              bookedValues={{ booked: aptBookingStats.booked.value, count: aptBookingStats.booked.quantity }}
                              minListino={dasProjectStats.minProjectPrice}
                              maxListino={dasProjectStats.maxProjectPrice}
                              minPromo={dasProjectStats.minProjectPrice * DAS_PROMO_MULTIPLIER}
                              maxPromo={dasProjectStats.maxProjectPrice * DAS_PROMO_MULTIPLIER}
                            />
                          )
                        ) : (
                          <DoughnutChartDashboardWrapper title={t('dashboard.economicStatus')} data={bookedNotBookedApt} innerRadius={50} outerRadius={75} height={!matchesLg ? 200 : 180} />
                        )}
                      </Grid>
                      <Grid item xs={12} lg={6} style={!matchesLg ? { paddingLeft: '16px' } : { paddingTop: '16px' }}>
                        {hasDAS ? (
                          dasProjectStats &&
                          aptBookingStats && (
                            <DasEconomicDashboard
                              title={t('dashboard.economicDetail')}
                              detailed
                              statuses={availableStatusesDAS}
                              bookedValues={{
                                propostaCount: aptBookingStats.booked.proposta.quantity,
                                compromessoCount: aptBookingStats.booked.compromesso.quantity,
                                rogitatoCount: aptBookingStats.booked.rogitato.quantity,
                                proposta: aptBookingStats.booked.proposta.value,
                                compromesso: aptBookingStats.booked.compromesso.value,
                                rogitato: aptBookingStats.booked.rogitato.value,
                                booked: aptBookingStats.booked.value,
                              }}
                              minListino={dasProjectStats.minProjectPrice}
                              maxListino={dasProjectStats.maxProjectPrice}
                              minPromo={dasProjectStats.minProjectPrice * DAS_PROMO_MULTIPLIER}
                              maxPromo={dasProjectStats.maxProjectPrice * DAS_PROMO_MULTIPLIER}
                            />
                          )
                        ) : (
                          <DoughnutChartDashboardWrapper
                            title={t('dashboard.economicDetail')}
                            data={doughnutApt}
                            innerRadius={50}
                            outerRadius={75}
                            height={!matchesLg ? 200 : 180}
                            showPromoLegend={!promoPrice.loading && !!promoPrice.data}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CrmCard>
                </Grid>
                {featuresData.length > 0 && (
                  <Grid item xs={10} sm={10} md={12} lg={12} data-testid='feat-chart' className='bottom-card main-container'>
                    <CrmCard style={{ padding: '0px' }}>
                      <div className='elements-spacing' style={!matchesLg ? { marginBottom: '36px' } : {}}>
                        <div className={!matches ? 'flex font-16' : 'font-16'}>
                          <div className='font-bold' style={!matches ? {} : { marginBottom: '16px' }}>
                            <Trans i18nKey={'label.tab.Pertinenze'} />
                          </div>
                          <div className={!matches ? 'flex-container-end' : ''} style={{ flex: '2' }}>
                            <div>
                              <div className={!matches ? 'flex-container-end' : 'small-font-14 flex-centered-between'}>
                                <span className='black' style={{ marginRight: '6px' }}>
                                  <Trans i18nKey={'dashboard.transactionValue'} />
                                </span>
                                <span className='font-bold'>{CurrencyFormatter(store.currency, sumObjArray(featuresData, 'price'))}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Grid container className='elements-spacing' style={{ width: 'auto' }}>
                        <Grid item xs={12} lg={6} className='border-right-pie-chart' style={!matches ? {} : { paddingBottom: '16px' }}>
                          <DoughnutChartDashboardWrapper title={t('dashboard.economicStatus')} data={bookedNotBookedFts} innerRadius={50} outerRadius={75} height={!matchesLg ? 200 : 180} />
                        </Grid>
                        <Grid item xs={12} lg={6} style={!matchesLg ? { paddingLeft: '16px' } : { paddingTop: '16px' }}>
                          <DoughnutChartDashboardWrapper title={t('dashboard.economicDetail')} data={doughnutFts} innerRadius={50} outerRadius={75} height={!matchesLg ? 200 : 180} />
                        </Grid>
                      </Grid>
                    </CrmCard>
                  </Grid>
                )}
                {(!store.dashboardConfig || (store.dashboardConfig.status_tagli && !store.dashboardConfig.status_tagli_venditeAffitto)) && (
                  <Grid item xs={10} sm={10} md={12} lg={12} className='border-grid'>
                    <CrmCard style={{ overflow: 'visible', padding: '0px' }}>
                      <div className='elements-spacing font-bold font-16'>
                        <Trans i18nKey={'dashboard.typologyStatus'} defaults={'Status Tipologia Appartamento'} />
                      </div>
                      <div className='elements-spacing'>
                        {verticalDataBars && deleteEmptyVertical(verticalDataBars).length > 0 ? (
                          <VerticalBarReChart data={deleteEmptyVertical(verticalDataBars)} availableStatuses={finalAvailableStats} availableColors={finalAvailableColors} size={barSize} />
                        ) : (
                          <NoDataChartDashboard />
                        )}
                      </div>
                    </CrmCard>
                  </Grid>
                )}
                <Grid item xs={10} sm={10} md={12} lg={12} data-testid='apt-dashboard-table' className='bottom-card'>
                  <CrmCard style={{ padding: '0px' }}>
                    <div className='elements-spacing'>
                      <CrmTable
                        data={appartments.data ? appartments.data.getAllAppartments.slice(0).sort((a, b) => b.updatedOn.localeCompare(a.updatedOn)) : []}
                        title={
                          <div className='font-bold font-16 table-title'>
                            <Trans i18nKey={'dashboard.lastVariations'} />
                          </div>
                        }
                        columns={ListaPreventiviDashboardTableConfig(store, t)}
                        paging
                        search
                        searchText={store.searchAppartamentiHome}
                        saveSearch={saveSearchAppartamenti}
                      />
                    </div>
                  </CrmCard>
                </Grid>
              </>
            )}

            {tabDashboard === 1 && (
              <>
                <Grid container spacing={4} className='dashboard-container-2'>
                  <Grid item xs={12} lg={6}>
                    <div style={{ border: '1px solid #e8e9eb', paddingBlock: 16, paddingInline: 10 }}>
                      <div className='title'>
                        <div className='font-bold' style={!matches ? {} : { marginBottom: '16px' }}>
                          <Trans i18nKey={'dashboard.utentiRegistrati'} />
                        </div>
                        <div>
                          <div>
                            <div>
                              <span className='black'>
                                <Trans i18nKey={'dashboard.numeroTotale'} />
                              </span>
                              <span data-testid='total-clients' className='font-bold'>
                                {totalClients}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {totalClients > 0 && <RegisteredUsersView from={store.clientsFromFilterDashboard} to={store.clientsToFilterDashboard} data={() => registerdUsers()} />}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div className='container'>
                      <div className='title'>
                        <div className='font-bold' style={!matches ? {} : { marginBottom: '16px' }}>
                          <Trans i18nKey={'dashboard.totaleAppuntamenti'} />
                        </div>
                        <ClickAwayListener onClickAway={handleTooltip}>
                          <div>
                            <InfoTooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltip}
                              open={openInfoTooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: t('dashboard.infoAppuntamenti', {
                                      interpolation: { escapeValue: false },
                                      Fissati: 'Appuntamenti Fissati:',
                                      Cancellati: 'Appuntamenti Cancellati:',
                                      Gestiti: 'Appuntamenti Gestiti:',
                                      Altri: 'Altri Appuntamenti Gestiti:',
                                      Scheduled: 'Scheduled Appointments:',
                                      Canceled: 'Canceled Appointments:',
                                      Managed: 'Managed Appointments:',
                                      Other: 'Other Managed Appointments:',
                                    }),
                                  }}
                                />
                              }
                              placement='bottom-end'
                            >
                              <Button className='info-button' onClick={handleTooltipButton}>
                                {<InfoOutlinedIcon fontSize='small' />}
                              </Button>
                            </InfoTooltip>
                          </div>
                        </ClickAwayListener>
                      </div>
                      <Grid container>
                        <Grid item xs={12} sm={3} className='sx'>
                          <div>
                            <div className={'font-16'}>
                              <Trans i18nKey={'dashboard.appuntamentiFissati'} />
                            </div>
                            <div data-testid='appuntamenti-fissati' className='number-scheduled-appointments'>
                              {appuntamentiFissati}
                            </div>
                          </div>
                          <div>
                            <div className={'font-16'}>
                              <Trans i18nKey={'dashboard.appuntamentiCancellati'} />
                            </div>
                            <div data-testid='appuntamenti-cancellati' className='number-scheduled-appointments'>
                              {appuntamentiCancellati}
                            </div>
                            {appuntamentiCancellati > 0 && (
                              <div data-testid='appuntamenti-cancellati-perc' className='perc'>
                                {calcolaPerc(appuntamentiFissati, appuntamentiCancellati)}%
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={9} className='dx'>
                          {appuntamentiGestitiTotali > 0 ? (
                            <TotalAppointmentsView data={totalAppointments} />
                          ) : (
                            <>
                              <Trans i18nKey={'dashboard.appuntamentiGestitiTotali'} /> = {appuntamentiGestitiTotali}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={10} sm={10} md={12} lg={12} className='bottom-card main-container'>
                  <CrmCard style={{ padding: '0px' }}>
                    <div className='elements-spacing'>
                      <CrmTable
                        data={store.retrieveClientsListDashboard.slice(0).sort((a, b) => b.createdOn.localeCompare(a.createdOn))}
                        title={
                          <div className='font-bold font-16 table-title'>
                            <Trans i18nKey={'dashboard.lastMovement'} />
                          </div>
                        }
                        columns={ListaClientiDashboardTableConfig(t)}
                        paging
                        search
                        saveSearch={saveSearchClienti}
                        searchText={store.searchClientiHome}
                      />
                    </div>
                  </CrmCard>
                </Grid>
              </>
            )}
            {tabDashboard === 2 && (
              <Grid container spacing={4} className='dashboard-container-2'>
                <Grid item xs={12} className='main-container' data-testid='typological-mix-card'>
                  <div>
                    {hasDAS && maxListDimension && minListDimension && (
                      <CrmCard style={{ padding: '0px' }}>
                        <div className='elements-spacing'>
                          <div className={!matches ? 'flex font-16' : 'font-16'}>
                            <div className='font-bold' style={!matches ? {} : { marginBottom: '16px' }}>
                              {t('dashboard.typologicalMix')}
                            </div>
                            <div className={!matches ? 'flex-container-end' : ''} style={{ flex: '2' }}>
                              <div>
                                <div className={!matches ? 'flex-container-end' : 'small-font-14 flex-centered-between'} data-testid='typological-min-price'>
                                  <span className='black' style={{ marginRight: '6px' }}>
                                    {t('dashboard.das.MinListino') + ':'}
                                  </span>
                                  <span className='font-bold'>{CurrencyFormatter(store.currency, dasProjectStats && dasProjectStats.minProjectPrice ? dasProjectStats.minProjectPrice : 0)}</span>
                                </div>
                                <div className={!matches ? 'flex-container-end' : 'small-font-14 flex-centered-between'} data-testid='typological-max-price'>
                                  <span className='black' style={{ marginRight: '6px' }}>
                                    {t('dashboard.das.MaxListino') + ':'}
                                  </span>
                                  <span className='font-bold'>{CurrencyFormatter(store.currency, dasProjectStats && dasProjectStats.maxProjectPrice ? dasProjectStats.maxProjectPrice : 0)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Grid container className='elements-spacing' style={{ width: 'auto' }}>
                          <Grid item xs={12} lg={6} className='border-right-pie-chart' style={!matches ? {} : { paddingBottom: '16px' }} data-testid='typological-min-graph'>
                            <DoughnutChartDashboardWrapper
                              title={t('dashboard.das.MinListino')}
                              data={minListDimension}
                              innerRadius={50}
                              outerRadius={75}
                              height={!matchesLg ? 200 : 180}
                              showPromoLegend={!promoPrice.loading && !!promoPrice.data}
                              focusFunction={(el) => clickOnMinDoughnutChart(el)}
                              type={'min'}
                              onFocus={doughnutApartmentOnFocus}
                              clickableLegend
                            />
                          </Grid>
                          <Grid item xs={12} lg={6} style={!matchesLg ? { paddingLeft: '16px' } : { paddingTop: '16px' }} data-testid='typological-max-graph'>
                            <DoughnutChartDashboardWrapper
                              title={t('dashboard.das.MaxListino')}
                              data={maxListDimension}
                              innerRadius={50}
                              outerRadius={75}
                              height={!matchesLg ? 200 : 180}
                              showPromoLegend={!promoPrice.loading && !!promoPrice.data}
                              focusFunction={(el) => clickOnMaxDoughnutChart(el)}
                              type={'max'}
                              onFocus={doughnutApartmentOnFocus}
                              clickableLegend
                            />
                          </Grid>
                        </Grid>
                      </CrmCard>
                    )}
                  </div>
                </Grid>
                {showDoughnutTable && (
                  <Grid item xs={10} sm={10} md={12} lg={12} className='bottom-card main-container' data-testid='typological-table'>
                    <CrmCard style={{ padding: '0px' }}>
                      <div className='elements-spacing'>
                        <CrmTable
                          data={tabMixTypologicalData}
                          title={
                            <div className='font-bold font-16 table-title' data-testid='typological-table-title'>
                              <Trans i18nKey={`dashboard.${doughnutApartmentOnFocus.split('_')[0]}`} defaults={doughnutApartmentOnFocus} />
                            </div>
                          }
                          columns={ListaMixTipologicoTableConfig(store, t)}
                          paging
                          search
                        />
                      </div>
                    </CrmCard>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export default Home;
