import React from 'react';
import Home from 'pages/Home';

import Dashboard from 'images/assets/dashboard.svg';
import Appartamenti from 'images/assets/appartamenti.svg';
import Clients from 'images/assets/clients.svg';
import CalendarIcon from 'images/assets/calendar.svg';
import GestioneInfoClientiIcon from 'images/assets/gestioneInfoClienti.svg';
import Preventivi from 'images/assets/ico_preventivi.svg';
import AccountManager from 'images/assets/accountManager.svg';
import ProposalManager from 'images/assets/proposalManager.svg';

const DettaglioCalendar = React.lazy(() => import('pages/DettaglioCalendar'));
const Calendar = React.lazy(() => import('pages/Calendar'));
const ListaClienti = React.lazy(() => import('pages/ListaClienti'));
const ListaAppartamenti = React.lazy(() => import('pages/ListaAppartamenti'));
const DettaglioCliente = React.lazy(() => import('pages/DettaglioCliente'));
const ModificaCliente = React.lazy(() => import('pages/ModificaCliente'));
const ModificaAppartamento = React.lazy(() => import('pages/ModificaAppartamento'));
const AggiungiCliente = React.lazy(() => import('pages/AggiungiCliente'));
const PdfViewer = React.lazy(() => import('pages/PdfViewer'));
const CreaAppartamenti = React.lazy(() => import('pages/CreaAppartamenti'));
const ModificaCalendar = React.lazy(() => import('pages/ModificaEvento'));
const ModificaSlotOccupato = React.lazy(() => import('pages/ModificaSlotOccupato'));
const CreaCalendar = React.lazy(() => import('pages/CreaEvento'));
const UserInfoManager = React.lazy(() => import('pages/UserInfoManager'));
const GestioneUtenze = React.lazy(() => import('pages/GestioneUtenze.jsx'));
const ListaPreventivi = React.lazy(() => import('pages/ListaPreventivi.jsx'));
const GestioneProposta = React.lazy(() => import('pages/GestioneProposta.jsx'));
const ModificaPertinenza = React.lazy(() => import('pages/ModificaPertinenza'));
const DocumentiHomeConfigurator = React.lazy(() => import('pages/DocumentiHomeConfigurator.jsx'));

export const HomeRoute = {
  id: 'home',
  order: 0,
  exact: true,
  to: '/',
  title: 'title.dashboard',
  navbarTitle: 'navbarTitle.dashboard',
  icon: Dashboard,
  navbar: true,
  goBack: false,
  component: Home,
};

export const appartamentiRoute = {
  id: 'apps',
  order: 1,
  exact: true,
  to: '/appartamenti',
  title: 'title.apartments',
  navbarTitle: 'navbarTitle.apartments',
  icon: Appartamenti,
  navbar: true,
  goBack: false,
  component: ListaAppartamenti,
};

export const clientiRoute = {
  id: 'clients',
  order: 2,
  exact: true,
  to: '/clienti',
  title: 'title.clientManager',
  navbarTitle: 'navbarTitle.clientManager',
  icon: Clients,
  navbar: true,
  goBack: false,
  component: ListaClienti,
};

export const GestioneUtentiRoute = {
  id: 'userInfoManager',
  order: 3,
  exact: true,
  to: '/userInfoManager',
  icon: GestioneInfoClientiIcon,
  title: 'title.userInfoManager',
  navbarTitle: 'navbarTitle.userInfoManager',
  navbar: true,
  goBack: false,
  component: UserInfoManager,
};

export const inboxRoute = {
  id: 'inbox',
  order: 4,
  exact: true,
  to: '/messaggi',
  title: 'title.messages',
  navbarTitle: 'navbarTitle.messages',
  navbar: true,
  goBack: false,
  component: Home,
};

export const calendarRoute = {
  id: 'calendar',
  order: 5,
  exact: true,
  to: '/calendario',
  icon: CalendarIcon,
  title: 'title.calendar',
  navbarTitle: 'navbarTitle.calendar',
  navbar: true,
  goBack: false,
  component: Calendar,
};

export const vendorRoute = {
  id: 'accountManager',
  order: 6,
  exact: true,
  to: '/accountManager',
  title: 'title.vendorManager',
  icon: AccountManager,
  navbarTitle: 'navbarTitle.vendorManager',
  navbar: true,
  goBack: false,
  component: GestioneUtenze,
};

export const listaPreventiviRoute = {
  id: 'quoteList',
  order: 7,
  exact: true,
  to: '/listaPreventivi',
  title: 'title.listaPreventivi',
  navbarTitle: 'navbarTitle.listaPreventivi',
  icon: Preventivi,
  navbar: true,
  goBack: false,
  component: ListaPreventivi,
};

export const appDetailRoute = {
  id: 'appdet',
  order: 8,
  exact: true,
  breadcrumb: appartamentiRoute.title,
  to: '/scheda-appartamento/:id',
  title: 'title.apartmentCard',
  navbarTitle: 'navbarTitle.apartmentCard',
  navbar: false,
  goBack: true,
  component: ModificaAppartamento,
};

export const pertinenzaDetailRoute = {
  id: 'pertinenzadett',
  order: 17,
  exact: true,
  breadcrumb: appartamentiRoute.title,
  to: '/scheda-pertinenza/:id',
  title: 'title.pertinenzaCard',
  navbarTitle: 'navbarTitle.pertinenzaCard',
  navbar: false,
  goBack: true,
  component: ModificaPertinenza,
};

export const clientDetailRoute = {
  id: 'clidet',
  order: 9,
  exact: true,
  breadcrumb: clientiRoute.title,
  to: '/scheda-cliente/:id',
  title: 'title.clientCard',
  navbarTitle: 'navbarTitle.clientCard',
  navbar: false,
  goBack: true,
  component: DettaglioCliente,
};

export const clientModifyRoute = {
  id: 'climod',
  order: 10,
  exact: true,
  breadcrumb: clientiRoute.title,
  to: '/modifica-cliente/:id',
  title: 'title.updateClientCard',
  navbarTitle: 'navbarTitle.updateClientCard',
  navbar: false,
  goBack: true,
  component: ModificaCliente,
};

export const clientAddRoute = {
  id: 'cliadd',
  order: 11,
  exact: true,
  breadcrumb: clientiRoute.title,
  to: '/aggiungi-cliente',
  title: 'title.addClient',
  navbarTitle: 'navbarTitle.addClient',
  navbar: false,
  goBack: true,
  component: AggiungiCliente,
};

export const calendarDetailRoute = {
  id: 'detcal',
  order: 12,
  exact: true,
  to: '/dettagli-calendario/:id',
  breadcrumb: calendarRoute.title,
  title: 'title.appointmentDetails',
  navbarTitle: 'navbarTitle.appointmentDetails',
  navbar: false,
  goBack: true,
  component: DettaglioCalendar,
};

export const calendarNewDetailRoute = {
  id: 'detcal',
  order: 12,
  exact: true,
  to: '/new-event/:id',
  breadcrumb: calendarRoute.title,
  title: 'title.createAppointment',
  navbarTitle: 'navbarTitle.createAppointment',
  navbar: false,
  goBack: true,
  component: CreaCalendar,
};

export const calendarUpdateDetailRoute = {
  id: 'detcal',
  order: 12,
  exact: true,
  to: '/update-event/:id',
  breadcrumb: calendarRoute.title,
  title: 'title.updateAppointment',
  navbarTitle: 'navbarTitle.updateAppointment',
  navbar: false,
  goBack: true,
  component: ModificaCalendar,
};

export const busySlotUpdateDetailRoute = {
  id: 'detcal',
  order: 13,
  exact: true,
  to: '/update-busy-slot/:id',
  breadcrumb: calendarRoute.title,
  title: 'title.updateSlot',
  navbarTitle: 'navbarTitle.updateSlot',
  navbar: false,
  goBack: true,
  component: ModificaSlotOccupato,
};

export const pdfViewerRoute = {
  id: 'pdf',
  order: 14,
  exact: true,
  to: '/pdf-viewer',
  title: 'title.visualizePdf',
  navbarTitle: 'navbarTitle.visualizePdf',
  navbar: false,
  goBack: true,
  component: PdfViewer,
};

export const CreaAppartamentiRoute = {
  id: 'createApp',
  order: 15,
  exact: true,
  to: '/crea-appartamenti',
  title: 'title.createtList',
  navbarTitle: 'navbarTitle.createtList',
  navbar: false,
  goBack: true,
  component: CreaAppartamenti,
};

export const GestionePropRoute = {
  id: 'gestione-prop',
  order: 16,
  exact: true,
  to: '/gestioneProposta',
  title: 'title.gestioneProposta',
  icon: ProposalManager,
  navbarTitle: 'navbarTitle.gestioneProposta',
  navbar: true,
  goBack: false,
  component: GestioneProposta,
};

export const DocumentiHCRoute = {
  id: 'documenti-hc',
  order: 17,
  exact: true,
  to: '/documenti-hc',
  navbarTitle: 'navbarTitle.documentiHC',
  title: 'navbarTitle.documentiHC',
  icon: Preventivi,
  navbar: true,
  goBack: false,
  component: DocumentiHomeConfigurator,
};

const RouteConsts = [
  HomeRoute,
  appartamentiRoute,
  clientiRoute,
  calendarRoute,
  calendarDetailRoute,

  vendorRoute,
  listaPreventiviRoute,
  appDetailRoute,
  pertinenzaDetailRoute,
  clientDetailRoute,
  clientModifyRoute,
  clientAddRoute,
  pdfViewerRoute,
  CreaAppartamentiRoute,
  calendarUpdateDetailRoute,
  calendarNewDetailRoute,
  busySlotUpdateDetailRoute,
  GestioneUtentiRoute,
  GestionePropRoute,
  DocumentiHCRoute,
];
export default RouteConsts;
